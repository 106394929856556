import React from "react"
import Seo from "../components/seo/Seo"

import Section from "../components/section/Section"
import SectionGrid from "../components/section/SectionGrid"
import SectionHeading from "../components/section/SectionHeading"
import SectionImage from "../components/section/SectionImage"
import SectionInfo from "../components/section/SectionInfo"
import SectionParagraph from "../components/section/SectionParagraph"

import geschaeftsfelder from "../assets/images/presentational/geschaeftsfelder.webp"
import technisches_asset_und_property_management from "../assets/images/presentational/technisches-asset-und-property-management.webp"
import projektmanagement from "../assets/images/presentational/projektmanagement.webp"
import ueber_uns from "../assets/images/presentational/ueber-uns.webp"
import SectionList from "../components/section/SectionList"
import SectionBanner from "../components/section/SectionBanner"

const Services = () => {
  return (
    <>
      <Seo
        title={"Geschäftsfelder | Avalum"}
        description={
          "Unsere Leistungen beruhen auf sachlichen Überlegungen, die höchsten Herausforderungen gerecht werden. Stets sind wir bestrebt, Beratungen und Leistungen anzubieten, die eine bedachte und gewinnbringende Bewirtschaftung garantieren."
        }
      />
      <Section>
        <SectionGrid>
          <SectionImage src={geschaeftsfelder} />
          <SectionInfo>
            <SectionHeading>Geschäftsfelder</SectionHeading>
            <SectionParagraph>
              Unsere Leistungen beruhen auf sachlichen Überlegungen, die
              höchsten Herausforderungen gerecht werden.
            </SectionParagraph>
            <SectionParagraph>
              Stets sind wir bestrebt, Beratungen und Leistungen anzubieten, die
              eine bedachte und gewinnbringende Bewirtschaftung garantieren.
            </SectionParagraph>
            <SectionParagraph>
              Die Immobilienwelt ist dynamisch, komplex und in stetiger
              Entwicklung.
            </SectionParagraph>
            <SectionParagraph>
              Nur ein überlegtes und begründetes Vorgehen im Umgang mit ihr
              garantiert Erfolge.
            </SectionParagraph>
            <SectionParagraph>
              Das Avalum-Team findet für jede Situation die passende Lösung
              heraus, um Sie als Kunden bei den gesetzten Immobilienzielen
              optimal zu begleiten.
            </SectionParagraph>
          </SectionInfo>
        </SectionGrid>
      </Section>
      <Section isEven={true}>
        <SectionGrid sx={"md:flex-row-reverse"}>
          <SectionImage src={technisches_asset_und_property_management} />
          <SectionInfo>
            <SectionHeading>
              Technisches Asset und Property Management
            </SectionHeading>
            <SectionList
              listItems={[
                "Steuerung des technischen Objektmanagements, des Facility Managements und sonstige Dienstleister im Sinne der Budgetnachhaltung, der Kontrolle für Betriebs-, Nebenkosten sowie Instandhaltungsbudgets",
                "Optimierung der Mieterzufriedenheit und Unterstützung von Vermietungsaktivitäten",
                "Analyse, Angebotseinholung und Auftragsvergabe von FM- und Bauleistungen",
                "Pflege der Wartungsverträge",
                "Begleitung von An- und Verkaufsprozessen",
                "Entwicklung und Umsetzung von Handlungsstrategien",
                "Begleitung baurechtlicher Genehmigungsverfahren und Weiterentwicklung von Immobilien",
                "Geltendmachung von Mängelhaftungs- (Gewährleistungs-) Ansprüchen",
                "Nachunternehmermanagement und Rechnungskontrolle",
                "Planung, Überwachung und Einhaltung des Budgets",
                "Durchführen von Immobilienbegehungen / Sicherheitsinspektionen und daraus abgeleiteter Maßnahmen",
              ]}
            />
          </SectionInfo>
        </SectionGrid>
      </Section>
      <Section>
        <SectionGrid>
          <SectionImage src={projektmanagement} />
          <SectionInfo>
            <SectionHeading>Projektmanagement</SectionHeading>
            <SectionList
              listItems={[
                "Bauprojektleitung",
                "Definition der Projektziele im Hinblick auf Kosten, Termine, Qualitäten",
                "Betreuung von Baumaßnahmen bei gekauften Bestandsobjekten und Neubauprojekten",
                "Einzelvergabe von Bauleistungen",
                "Übernahme der Projektleitung bis zur Baurechterlangung einschließlich der Führung des Planungsteams",
                "Kostenplanung und Kontrolle sowie Dokumentation und Reporting zur Geschäftsführung",
              ]}
            />
          </SectionInfo>
        </SectionGrid>
      </Section>
      <SectionBanner
        bg={ueber_uns}
        to={"/kontakt"}
        title={"Kontaktieren Sie uns"}
        heading={"Überlassen Sie die Arbeit den Profis"}
      />
    </>
  )
}

export default Services
