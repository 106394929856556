import React, { useRef, useEffect } from "react"
import gsap from "gsap"
import { ScrollTrigger } from "gsap/ScrollTrigger"
gsap.registerPlugin(ScrollTrigger)
const SectionImage = ({ src }) => {
  const imgRef = useRef()
  useEffect(() => {
    gsap.to(imgRef.current, {
      scrollTrigger: {
        trigger: imgRef.current,
        start: "top bottom",
        toggleActions: "play none none reverse",
      },
      scale: 1,
      duration: 1,
    })
  })
  return (
    <div className="h-60 flex-1 sm:h-64 md:h-68 lg:h-72">
      <img
        alt=""
        className="h-full object-cover w-full scale-125"
        src={src}
        ref={imgRef}
      />
    </div>
  )
}

export default SectionImage
