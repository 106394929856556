import React, { useRef, useEffect } from "react"
import gsap from "gsap"
import { ScrollTrigger } from "gsap/ScrollTrigger"

gsap.registerPlugin(ScrollTrigger)

const SectionList = ({ listItems }) => {
  const listRef = useRef()
  useEffect(() => {
    gsap.to(listRef.current, {
      scrollTrigger: {
        trigger: listRef.current,
        start: "top bottom",
        toggleActions: "play none none reverse",
      },
      x: 0,
      opacity: 1,
      duration: 1,
    })
  })
  return (
    <ul
      className="opacity-0 -translate-x-8 grid gap-y-8 list-disc p-4 font-medium"
      ref={listRef}
    >
      {listItems.map((listItem, i) => {
        return <li key={i}>{listItem}</li>
      })}
    </ul>
  )
}

export default SectionList
