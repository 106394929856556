import React from "react"
import SectionHeading from "./SectionHeading"
import SectionLink from "./SectionLink"

const SectionBanner = ({ bg, className, heading, to, title }) => {
  return (
    <section
      className="bg-cover bg-center flex min-h-80 items-center justify-center relative w-screen md:h-120 lg:min-h-sh before:grayscale before:absolute before:bg-black before:content-[''] before:inset-0 before:opacity-50"
      style={{ backgroundImage: `url(${bg})` }}
    >
      <div className="max-w-content mx-auto text-center relative w-full">
        <div className="grid gap-y-8 items-center justify-center text-white">
          <SectionHeading>{heading}</SectionHeading>
          <SectionLink to={to} title={title} />
        </div>
      </div>
    </section>
  )
}

export default SectionBanner
