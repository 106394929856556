import React, { useRef, useEffect } from "react"
import gsap from "gsap"
import { ScrollTrigger } from "gsap/ScrollTrigger"
import AniLink from "gatsby-plugin-transition-link/AniLink"

gsap.registerPlugin(ScrollTrigger)

const SectionLink = ({ sx, to, title }) => {
  const linkRef = useRef()
  useEffect(() => {
    gsap.to(linkRef.current, {
      scrollTrigger: {
        trigger: linkRef.current,
        start: "top bottom",
        toggleActions: "play none none reverse",
      },
      x: 0,
      opacity: 1,
      duration: 1,
    })
  })
  return (
    <div className="opacity-0 -translate-x-8" ref={linkRef}>
      <AniLink
        paintDrip
        hex="#0d1627"
        className="mt-4 sm:mt-6 md:mt-8 border-b-2 border-brand-secondary px-2 pb-2 w-fit font-medium text-sm uppercase sm:text-base sm:px-4 md:px-6"
        to={to}
        title={title}
      >
        {title}
      </AniLink>
    </div>
  )
}

export default SectionLink
